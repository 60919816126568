import { useEdgeMatchingRoutineData } from "queries/EMQueries";
import { EMPlotProps } from "./EMPlot";
import TableInfoHeader from "components/SharedComponents/TableInfoHeader";
import { useState } from "react";
import EdgeMeasurementResultsTable from "./EdgeMeasurementResultsTable";
import LeakageResultsTable from "./LeakageResultsTable";
import { EdgeRoutineResultsType } from "types/EMTypes";
import RoutineInfoTable from "components/SharedComponents/Routines/RoutineInfoTable";
import { ContextMenu } from "@domin-frontend/domin-ui";
import { toast } from "react-toastify";

interface EMRoutineInfoProps extends EMPlotProps {}

function isEdgeTable(data: any): data is EdgeRoutineResultsType {
  return (
    data.p_s1 !== undefined &&
    data.p_s2 !== undefined &&
    data.r_s1 !== undefined &&
    data.r_s2 !== undefined
  );
}

export default function EMRoutineInfo({
  routineId,
  type,
  isLinear,
}: EMRoutineInfoProps) {
  const [isResultsOpen, setIsResultsOpen] = useState(true);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [savedBaseSku, setBaseSku] = useState<string>();
  
  const {
    data: routineData,
    isLoading,
    isError,
  } = useEdgeMatchingRoutineData({
    id: routineId,
    type: type,
    linear: isLinear,
    base_sku: savedBaseSku,
  });

  if (isLoading || isError || !routineData) {
    return (
      <div
        id="routine-info"
        className="relative min-w-60 max-h-[60vh] h-full overflow-y-auto overflow-x-hidden"
      />
    );
  }
  const { results } = routineData;

  return (
    <div
      id="routine-info"
      className="relative min-w-fit max-h-[60vh] h-full overflow-y-auto overflow-x-hidden"
    >
      <ContextMenu
        dropdown={[
          <p
            className="bg-neutral-0 shadow p-2 whitespace-nowrap cursor-copy"
            // copy to clipboard
            onClick={() => {
              navigator.clipboard.writeText(routineId ?? "");
              toast.success("Routine ID copied to clipboard");
            }}
          >
            Copy Routine ID: {routineId ?? ""}
          </p>,
        ]}
      >
        <RoutineInfoTable
          info={{ id: routineId, ...routineData.info }}
          isOpen={isInfoOpen}
          setIsOpen={setIsInfoOpen}
          title="Routine Info"
        />
      </ContextMenu>

      <div
        className={`transition xl:min-w-[24rem]  ${
          isResultsOpen ? "h-auto" : "h-8"
        }`}
      >
        {(["front_edge", "back_edge"].includes(type) || results) && (
          <TableInfoHeader
            onClick={() => setIsResultsOpen(!isResultsOpen)}
            isOpen={isResultsOpen}
          >
            Production Info
          </TableInfoHeader>
        )}
        {isResultsOpen &&
        (type === "front_edge" || type === "back_edge") &&
        isEdgeTable(results) ? (
          <EdgeMeasurementResultsTable
            isOpen={isResultsOpen}
            routineId={routineId}
            type={type}
            p_s1={results.p_s1}
            p_s2={results.p_s2}
            r_s1={results.r_s1}
            r_s2={results.r_s2}
            isLinear={isLinear}
            setBaseSku={setBaseSku}
          />
        ) : (
          type === "leakage" &&
          !isEdgeTable(results) &&
          results && (
            <LeakageResultsTable isOpen={isResultsOpen} results={results} />
          )
        )}
      </div>
    </div>
  );
}
