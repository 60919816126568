import { useFormik } from "formik";
import {
  SpecHead,
  SpecName,
  SpecValue,
} from "components/PAS/PASRoutineItems/RoutineSpecs";
import { formatDigits } from "helpers/functions/shared/formatDigits";
import { calcLandStatus } from "helpers/functions/edgeMatching/calcLandStatus";
import { useEffect, useState } from "react";
import { Button, SingleSelect, TextInput } from "@domin-frontend/domin-ui";
import {
  usePostEdgeObjectiveCutData,
  useEdgeMatchingInfo,
  usePostAnalyseDecision,
  usePostMatchStatusData,
} from "queries/EMQueries";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  AnalyseDecisionType,
  MatchStatusType,
} from "../../types/responseTypes";

function formatMMtoUM(mm: number, isLinear: boolean) {
  if (isLinear) {
    return formatDigits(mm);
  }
  return formatDigits(mm * 1000);
}

interface EdgeResultData {
  position: number;
  cut_depth: number;
  estimated_edge?: number;
  cut_wedm?: number;
  objective?: number;
  null_shift?: number;
  corrected_edge?: number;
  upper_limit?: number;
  lower_limit?: number;
}

interface EdgeMeasurementResultsProps {
  isOpen: boolean;
  routineId: string;
  type: "front_edge" | "back_edge";
  isLinear: boolean;
  p_s1?: EdgeResultData;
  p_s2?: EdgeResultData;
  r_s1?: EdgeResultData;
  r_s2?: EdgeResultData;
  setBaseSku: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function EdgeMeasurementResultsTable({
  isOpen,
  routineId,
  type,
  p_s1,
  p_s2,
  r_s1,
  r_s2,
  isLinear,
  setBaseSku,
}: EdgeMeasurementResultsProps) {
  const { manifold_spool } = useParams();
  const urlManifold = manifold_spool?.split("-")[0];
  const urlSpool = manifold_spool?.split("-")[1];
  const { data: manifoldData } = useEdgeMatchingInfo(urlManifold, urlSpool);
  const [newAnalyseDecision, setAnalyseDecision] = useState<
    AnalyseDecisionType
  >();
  const [newMatchStatus, setNewMatchStatus] = useState<MatchStatusType>();

  const formik = useFormik({
    initialValues: {
      p_s1: p_s1?.cut_wedm ?? undefined,
      p_s2: p_s2?.cut_wedm ?? undefined,
      r_s1: r_s1?.cut_wedm ?? undefined,
      r_s2: r_s2?.cut_wedm ?? undefined,
      baseSku: "",
      p_s1_upper: p_s1?.upper_limit ?? undefined,
      p_s1_lower: p_s1?.lower_limit ?? undefined,
      p_s2_upper: p_s2?.upper_limit ?? undefined,
      p_s2_lower: p_s2?.lower_limit ?? undefined,
      r_s1_upper: r_s1?.upper_limit ?? undefined,
      r_s1_lower: r_s1?.lower_limit ?? undefined,
      r_s2_upper: r_s2?.upper_limit ?? undefined,
      r_s2_lower: r_s2?.lower_limit ?? undefined,
    },
    // This is here to make formik happy
    onSubmit: () => {},
  });

  const { mutate, isSuccess, isPending } = usePostEdgeObjectiveCutData(
    routineId,
    type
  );

  const {
    mutate: analyseDecision,
    isPending: analysePending,
  } = usePostAnalyseDecision();

  const { mutate: matchStatus } = usePostMatchStatusData();

  // Check if the user is an EM user and if so, allow them to edit the cuts
  const { accounts } = useMsal();
  const email = accounts[0].username.toLocaleLowerCase();
  const em_users = process.env.REACT_APP_EM_USERS?.split(",") ?? [];
  const isEMUser = em_users.includes(email);
  const val_users = process.env.REACT_APP_VAL_USERS?.split(",") ?? [];
  const isValUser = val_users.includes(email);
  const isRotaryDisabled = true;

  useEffect(() => {
    if (urlManifold && urlSpool && isEMUser && formik.values.baseSku) {
      mutate({
        id: `${urlManifold}-${urlSpool}`,
        baseSku: formik.values.baseSku,
        currentEdges: [
          p_s1?.estimated_edge ?? 0,
          p_s2?.estimated_edge ?? 0,
          r_s1?.estimated_edge ?? 0,
          r_s2?.estimated_edge ?? 0,
        ],
      });
    }
  }, [
    formik.values.baseSku,
    urlManifold,
    urlSpool,
    isEMUser,
    mutate,
    p_s1?.estimated_edge,
    p_s2?.estimated_edge,
    r_s1?.estimated_edge,
    r_s2?.estimated_edge,
  ]);

  useEffect(() => {
    if (isSuccess && formik.values.baseSku) {
      setBaseSku(formik.values.baseSku);
    }
  }, [isSuccess, setBaseSku, formik.values.baseSku]);

  useEffect(() => {
    if (newAnalyseDecision) {
      analyseDecision({
        manifold_spool: `${urlManifold}-${urlSpool}`,
        routine_id: routineId,
        analyse_decision: newAnalyseDecision,
        current_edges: [
          p_s1?.estimated_edge ?? 0,
          p_s2?.estimated_edge ?? 0,
          r_s1?.estimated_edge ?? 0,
          r_s2?.estimated_edge ?? 0,
        ],
        null_shift: p_s1?.null_shift ?? 0,
        corrected_edges: [
          p_s1?.corrected_edge ?? 0,
          p_s2?.corrected_edge ?? 0,
          r_s1?.corrected_edge ?? 0,
          r_s2?.corrected_edge ?? 0,
        ],
        objectives: [
          p_s1?.objective ?? 0,
          p_s2?.objective ?? 0,
          r_s1?.objective ?? 0,
          r_s2?.objective ?? 0,
        ],
        wedm_input: [
          formik.values.p_s1 ?? 0,
          formik.values.p_s2 ?? 0,
          formik.values.r_s1 ?? 0,
          formik.values.r_s2 ?? 0,
        ],
      });
      setAnalyseDecision(undefined);
    }
  }, [
    urlManifold,
    urlSpool,
    analyseDecision,
    newAnalyseDecision,
    routineId,
    p_s1?.estimated_edge,
    p_s2?.estimated_edge,
    r_s1?.estimated_edge,
    r_s2?.estimated_edge,
    p_s1?.null_shift,
    p_s1?.corrected_edge,
    p_s2?.corrected_edge,
    r_s1?.corrected_edge,
    r_s2?.corrected_edge,
    p_s1?.objective,
    p_s2?.objective,
    r_s1?.objective,
    r_s2?.objective,
    formik.values.p_s1,
    formik.values.p_s2,
    formik.values.r_s1,
    formik.values.r_s2,
  ]);

  useEffect(() => {
    if (newMatchStatus) {
      matchStatus({
        manifold_spool: `${urlManifold}-${urlSpool}`,
        matchStatus: newMatchStatus,
      });
    }
  }, [newMatchStatus, matchStatus, urlManifold, urlSpool]);

  return (
    <>
      {isEMUser && manifoldData?.suitable_skus && isLinear && (
        <div className="flex justify-center pt-2 gap-2">
          <>
            {manifoldData?.suitable_skus?.length > 0 && (
              <div className="w-full">
                {formik.values.baseSku ? (
                  <TextInput
                    name="base sku"
                    disabled
                    value={formik.values.baseSku}
                  />
                ) : (
                  <div>
                    <SingleSelect
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          baseSku: e.value,
                        });
                      }}
                      options={manifoldData?.suitable_skus?.map((o) => {
                        return { value: o, label: o };
                      })}
                      value={formik.values.baseSku ?? ""}
                      placeholder="Select Base SKU..."
                    />
                  </div>
                )}
              </div>
            )}
            {formik.values.baseSku && (
              <Button
                variant="ghost"
                onClick={() => {
                  formik.resetForm();
                }}
                loading={isPending}
              >
                Edit
              </Button>
            )}
          </>
        </div>
      )}

      <br></br>

      {manifoldData?.variant === "S4 Pro" || !isLinear ? (
        <table
          className={`w-full transition-all -translate-y-56 ${
            isOpen
              ? "scale-y-100 translate-y-0"
              : "scale-y-0 -translate-y-2/4 h-0"
          }`}
        >
          <thead>
            <tr>
              <th className="pl-1" />
              {p_s2 && <SpecHead>P-S2</SpecHead>}
              {r_s2 && <SpecHead>R-S2</SpecHead>}
              {r_s1 && <SpecHead>R-S1</SpecHead>}
              {p_s1 && <SpecHead>P-S1</SpecHead>}
            </tr>
          </thead>
          <tbody>
            <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
              <SpecName>Position (deg)</SpecName>
              {p_s2 && <SpecValue>{formatDigits(p_s2?.position, 4)}</SpecValue>}
              {r_s2 && <SpecValue>{formatDigits(r_s2?.position, 4)}</SpecValue>}
              {r_s1 && <SpecValue>{formatDigits(r_s1?.position, 4)}</SpecValue>}
              {p_s1 && <SpecValue>{formatDigits(p_s1?.position, 4)}</SpecValue>}
            </tr>
            <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
              <SpecName>Cut Depth (μm)</SpecName>
              {p_s2 && (
                <SpecValue>{formatMMtoUM(p_s2?.cut_depth, isLinear)}</SpecValue>
              )}
              {r_s2 && (
                <SpecValue>{formatMMtoUM(r_s2?.cut_depth, isLinear)}</SpecValue>
              )}
              {r_s1 && (
                <SpecValue>{formatMMtoUM(r_s1?.cut_depth, isLinear)}</SpecValue>
              )}
              {p_s1 && (
                <SpecValue>{formatMMtoUM(p_s1?.cut_depth, isLinear)}</SpecValue>
              )}
            </tr>
            <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
              <SpecName>Estimated Edge (deg)</SpecName>
              {
                <SpecValue>
                  {p_s2?.estimated_edge &&
                    formatDigits(p_s2?.estimated_edge, 4)}
                </SpecValue>
              }
              {
                <SpecValue>
                  {r_s2?.estimated_edge &&
                    formatDigits(r_s2?.estimated_edge, 4)}
                </SpecValue>
              }
              {
                <SpecValue>
                  {r_s1?.estimated_edge &&
                    formatDigits(r_s1?.estimated_edge, 4)}
                </SpecValue>
              }
              {
                <SpecValue>
                  {p_s1?.estimated_edge &&
                    formatDigits(p_s1?.estimated_edge, 4)}
                </SpecValue>
              }
            </tr>
            {isEMUser && !isRotaryDisabled && (
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>WEDM Input (mm)</SpecName>
                {<SpecValue>{formatDigits(formik.values.p_s2, 4)}</SpecValue>}
                {<SpecValue>{formatDigits(formik.values.r_s2, 4)}</SpecValue>}
                {<SpecValue>{formatDigits(formik.values.r_s1, 4)}</SpecValue>}
                {<SpecValue>{formatDigits(formik.values.p_s1, 4)}</SpecValue>}
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <>
          <table
            className={`w-full transition-all -translate-y-56 ${
              isOpen
                ? "scale-y-100 translate-y-0"
                : "scale-y-0 -translate-y-2/4 h-0"
            }`}
          >
            <thead>
              <tr>
                <th className="pl-1" />
                {p_s1 && <SpecHead>P-S1</SpecHead>}
                {p_s2 && <SpecHead>P-S2</SpecHead>}
                {r_s1 && <SpecHead>R-S1</SpecHead>}
                {r_s2 && <SpecHead>R-S2</SpecHead>}
              </tr>
            </thead>
            <tbody>
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>Estimated Edge (μm)</SpecName>
                {
                  <SpecValue>
                    {p_s1?.estimated_edge &&
                      formatDigits(p_s1?.estimated_edge, 4)}
                  </SpecValue>
                }
                {
                  <SpecValue>
                    {p_s2?.estimated_edge &&
                      formatDigits(p_s2?.estimated_edge, 4)}
                  </SpecValue>
                }
                {
                  <SpecValue>
                    {r_s1?.estimated_edge &&
                      formatDigits(r_s1?.estimated_edge, 4)}
                  </SpecValue>
                }
                {
                  <SpecValue>
                    {r_s2?.estimated_edge &&
                      formatDigits(r_s2?.estimated_edge, 4)}
                  </SpecValue>
                }
              </tr>
              {isValUser && (
                <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                  <SpecName>Null Center (μm)</SpecName>
                  {<SpecValue>{formatDigits(p_s1?.null_shift, 4)}</SpecValue>}
                  {<SpecValue>{formatDigits(p_s2?.null_shift, 4)}</SpecValue>}
                  {<SpecValue>{formatDigits(r_s1?.null_shift, 4)}</SpecValue>}
                  {<SpecValue>{formatDigits(r_s2?.null_shift, 4)}</SpecValue>}
                </tr>
              )}
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>Objective (μm)</SpecName>
                {<SpecValue>{formatDigits(p_s1?.objective, 4)}</SpecValue>}
                {<SpecValue>{formatDigits(p_s2?.objective, 4)}</SpecValue>}
                {<SpecValue>{formatDigits(r_s1?.objective, 4)}</SpecValue>}
                {<SpecValue>{formatDigits(r_s2?.objective, 4)}</SpecValue>}
              </tr>
              {isEMUser && (
                <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                  <SpecName>Cut to Match (mm)</SpecName>
                  {<SpecValue>{formatDigits(formik.values.p_s1, 4)}</SpecValue>}
                  {<SpecValue>{formatDigits(formik.values.p_s2, 4)}</SpecValue>}
                  {<SpecValue>{formatDigits(formik.values.r_s1, 4)}</SpecValue>}
                  {<SpecValue>{formatDigits(formik.values.r_s2, 4)}</SpecValue>}
                </tr>
              )}
            </tbody>
          </table>
          <table
            className={`w-full transition-all -translate-y-56 ${
              isOpen
                ? "scale-y-100 translate-y-0"
                : "scale-y-0 -translate-y-2/4 h-0"
            }`}
          >
            <thead>
              <tr>
                <th className="pl-1" />
                {<SpecHead>Lower Limit</SpecHead>}
                {<SpecHead>Current</SpecHead>}
                {<SpecHead>Upper Limit</SpecHead>}
              </tr>
            </thead>
            <tbody>
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>P-S1 Overlap (μm)</SpecName>
                {<SpecValue>{formik.values.p_s1_lower}</SpecValue>}
                {
                  <SpecValue
                    status={calcLandStatus({
                      current: p_s1?.corrected_edge,
                      lowerLimit: formik.values.p_s1_lower,
                      upperLimit: formik.values.p_s1_upper,
                    })}
                  >
                    {formatDigits(p_s1?.corrected_edge, 4)}
                  </SpecValue>
                }
                {<SpecValue>{formik.values.p_s1_upper}</SpecValue>}
              </tr>
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>P-S2 Overlap (μm)</SpecName>
                {<SpecValue>{formik.values.p_s2_lower}</SpecValue>}
                {
                  <SpecValue
                    status={calcLandStatus({
                      current: p_s2?.corrected_edge,
                      lowerLimit: formik.values.p_s2_lower,
                      upperLimit: formik.values.p_s2_upper,
                    })}
                  >
                    {formatDigits(p_s2?.corrected_edge, 4)}
                  </SpecValue>
                }
                {<SpecValue>{formik.values.p_s2_upper}</SpecValue>}
              </tr>
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>R-S1 Overlap (μm)</SpecName>
                {<SpecValue>{formik.values.r_s1_lower}</SpecValue>}
                {
                  <SpecValue
                    status={calcLandStatus({
                      current: r_s1?.corrected_edge,
                      lowerLimit: formik.values.r_s1_lower,
                      upperLimit: formik.values.r_s1_upper,
                    })}
                  >
                    {formatDigits(r_s1?.corrected_edge, 4)}
                  </SpecValue>
                }
                {<SpecValue>{formik.values.r_s1_upper}</SpecValue>}
              </tr>
              <tr className="h-8 hover:bg-primary-600 hover:text-neutral-0 transition-colors">
                <SpecName>R-S2 Overlap (μm)</SpecName>
                {<SpecValue>{formik.values.r_s2_lower}</SpecValue>}
                {
                  <SpecValue
                    status={calcLandStatus({
                      current: r_s2?.corrected_edge,
                      lowerLimit: formik.values.r_s2_lower,
                      upperLimit: formik.values.r_s2_upper,
                    })}
                  >
                    {formatDigits(r_s2?.corrected_edge, 4)}
                  </SpecValue>
                }
                {<SpecValue>{formik.values.r_s2_upper}</SpecValue>}
              </tr>
            </tbody>
          </table>
          <br></br>
          {isValUser && (
            <>
              <div className="w-full flex gap-2">
                <Button
                  className="w-full"
                  variant="secondary"
                  onClick={() => {
                    setAnalyseDecision("Send for Cut");
                  }}
                  loading={analysePending}
                >
                  Request Cut
                </Button>
                <Button
                  className="w-full"
                  variant="primary"
                  onClick={() => {
                    setAnalyseDecision("Sent for PAS");
                    setNewMatchStatus("validated");
                  }}
                  loading={analysePending}
                >
                  Send for PAS
                </Button>
              </div>
              <br></br>
              <div className="w-full flex gap-2">
                <Button
                  className="w-full"
                  variant="danger"
                  onClick={() => {
                    setAnalyseDecision("Scrap");
                    setNewMatchStatus("scrapped");
                  }}
                  loading={analysePending}
                >
                  Send for Scrap
                </Button>
                <Button
                  className="w-full"
                  variant="danger"
                  onClick={() => {
                    setAnalyseDecision("Remeasure");
                    setNewMatchStatus("remeasure");
                  }}
                  loading={analysePending}
                >
                  Request Remeasure
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
